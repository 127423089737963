import * as React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import addStylesToContent from "../addStylesToContent";

const Emphasize = ({ children }) => (
	<span className="text-teal-700 dark:text-teal-500">{children}</span>
);

const IndexPage = () => (
	<Layout padMain={false}>
		<SEO title="Home" />
		<div className="w-full bg-gray-700 dark:bg-gray-700 text-white">
			<div className="container mx-auto py-6 md:py-16">
				<div className="mx-6">
					<h1 className="text-3xl md:text-5xl">
						Welcome to{" "}
						<span className="text-teal-400">Learn the Meta</span>.
					</h1>
					<div className="mt-8 text-lg md:text-xl">
						<p className="">
							A site about getting the{" "}
							<span className="text-teal-400">
								best life possible
							</span>
						</p>
						<p className="mt-2">
							as <span className="text-teal-400">quickly</span> as
							possible
						</p>
						<p className="mt-2">
							with the{" "}
							<span className="text-teal-400">least effort</span>{" "}
							possible.
						</p>
					</div>
				</div>
			</div>
		</div>
		<div className="container mx-auto mt-4">
			<article className="mx-6">
				{addStylesToContent(
					<>
						<h2>What can you find here?</h2>
						<p></p>
						<p>
							The best strategies to get what you want out of
							life.
						</p>
						<p>
							And by best, I mean the{" "}
							<Emphasize>most results</Emphasize> for the{" "}
							<Emphasize>least effort</Emphasize>.
						</p>
						<p>
							It's a digest of psychology, neuroscience, game
							theory, strategy, nutrition, physiology, and many
							other fields.
						</p>
						<p>We take what works, and disregard what doesn't.</p>
						<h2>What should you read first?</h2>
						<p>
							Read <Link to="/start-here/">this page</Link> for a
							list of which articles to start with.
						</p>
						<h2>Why is this site necessary?</h2>
						<p>Because this world doesn't play fair.</p>
						<p>
							It's full of complex systems which trick people into
							doing what's best for the system, rather than what's
							best for them.
						</p>
						<p>
							In this world you can be smart and hard working,
							desire wealth, and somehow end up in debt anyway.
						</p>
						<p>Or obese, when you want to be fit. </p>
						<p>
							Or an internet addict, when you want to be
							productive.
						</p>
						<p>
							I've known people like that, and I've suffered from
							it myself. Maybe you have, too.
						</p>
						<p>
							I can't change the system, but I can teach you how
							to exploit it, rather than be exploited.
						</p>
						<h2>Why is it called "Learn the Meta"?</h2>
						<p>
							The "meta" is a term originally from competitive
							e-sports.{" "}
						</p>
						<p>
							It means the strategies and tactics that are the
							most effective way to play the game.
						</p>
						<p>
							In short,{" "}
							<Emphasize>
								learning the meta is learning to win
							</Emphasize>
							.
						</p>
						<p>
							This site obviously isn't about winning at e-sports,
							though. It's about the most effective ways to win at
							life.
						</p>
						<p>
							<Link to="/start-here/">
								Let's get you started.
							</Link>
						</p>
						<p className="mb-8"></p>
					</>
				)}
			</article>
		</div>
	</Layout>
);

export default IndexPage;
