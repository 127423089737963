import React from "react";

const addStylesToContent = topLevelFragment => {
	const children = topLevelFragment.props.children;
	return React.Children.map(children, addClassesForElementTagName);
};

export default addStylesToContent;

const addClassesForElementTagName = element => {
	if (!element) return null;

	const tagName = getElementTagName(element);

	if (tagName === "fragment") {
		// Fragment is a special case. Instead of trying to add styles
		// to it, we ignore it and add the classes to it's first valid
		// child instead.
		return React.Children.map(
			element.props.children,
			addClassesForElementTagName
		);
	}

	const addClassesFn = addClassesForTagNameFnMap[tagName];
	return addClassesFn ? addClassesFn(element) : element;
};

export const getElementTagName = element => {
	const elementType = getElementType(element);
	switch (elementType) {
		case "baseHtml":
			return element.type;

		case "react":
			return element.type.name;

		case "fragment":
			return "fragment";

		case "unknown":
		default:
			// IDC about this now
			// console.error(
			// 	"Unable to determine tag name for element: ",
			// 	element
			// );
			return null;
	}
};

const getElementType = element => {
	if (typeof element.type === "string") {
		return "baseHtml";
	}

	if (typeof element.type === "function") {
		return "react";
	}

	if (typeof element.type === "symbol") {
		return "fragment";
	}

	return "unknown";
};

const addClassesForTagNameFnMap = {
	// Never put a h1 into your articles! The h1 should be for heading only, everything else h2 or below. This makes it better for screenreader users.
	h2: el =>
		addSpecifiedClasses(
			addIdFromText(el),
			"mt-8 text-2xl font-semibold text-teal-800 dark:text-teal-400"
		),
	h3: el =>
		addSpecifiedClasses(
			addIdFromText(el),
			"mt-8 text-xl font-semibold text-teal-700 dark:text-teal-400"
		),
	h4: el =>
		addSpecifiedClasses(
			addIdFromText(el),
			"mt-8 text-lg font-semibold text-teal-700 dark:text-teal-400"
		),
	p: el =>
		addSpecifiedClasses(
			el,
			"max-w-lg mt-4 first-of-type:text-teal-700 dark:first-of-type:text-teal-300 first-of-type:text-lg first-of-type:mt-8"
			//"max-w-lg mt-4"
		),
	Image: el => addSpecifiedClasses(el, "w-full mt-4 rounded overflow-hidden"),
	table: table => {
		let result = addSpecifiedClasses(
			table,
			"mt-4 shadow-lg dark:shadow-none rounded w-full max-w-lg"
		);

		result = recursivelyApplyToChildren(result, child => {
			const tag = getElementTagName(child);

			if (tag === "caption") {
				return addSpecifiedClasses(
					child,
					"text-left ml-3 mb-2 text-gray-500 text-sm table-caption-top"
				);
			} else if (tag === "th") {
				return addSpecifiedClasses(
					child,
					"p-3 first:rounded-tl-md last:rounded-tr-md " +
						"text-left uppercase text-sm font-normal " +
						"bg-gray-100 text-gray-700 " +
						"dark:bg-gray-700 dark:text-gray-100"
				);
			} else if (tag === "td") {
				return addSpecifiedClasses(
					child,
					"px-3 py-2 text-gray-800 dark:text-gray-100"
				);
			}
			return child;
		});

		return result;
	},
	ol: el => {
		const olWithClasses = addSpecifiedClasses(
			el,
			"max-w-lg list-decimal list-outside mt-4 ml-4"
		);

		const children = React.Children.map(
			olWithClasses.props.children,
			child => {
				if (getElementTagName(child) !== "li") {
					return child;
				}
				return addSpecifiedClasses(child, "pl-2 mt-1");
			}
		);

		return React.cloneElement(olWithClasses, olWithClasses.props, children);
	},
	ul: el => {
		const olWithClasses = addSpecifiedClasses(
			el,
			"max-w-lg list-disc list-outside mt-4 ml-4"
		);

		const children = React.Children.map(
			olWithClasses.props.children,
			child => {
				if (getElementTagName(child) !== "li") {
					return child;
				}
				return addSpecifiedClasses(child, "pl-2 mt-1");
			}
		);

		return React.cloneElement(olWithClasses, olWithClasses.props, children);
	},
	blockquote: el =>
		addSpecifiedClasses(
			el,
			"max-w-lg mt-4 px-4 py-2 border-l-2 " +
				"bg-gray-100 border-teal-700 text-teal-700 " +
				"dark:bg-gray-700 dark:border-teal-600 dark:text-teal-300 "
		),
};

const recursivelyApplyToChildren = (el, fn) => {
	if (!el || !el.props || React.Children.count(el) === 0) {
		return el;
	}

	const children = React.Children.map(el.props.children, child => {
		let alteredChild = fn(child);
		return recursivelyApplyToChildren(alteredChild, fn);
	});

	return React.cloneElement(el, el.props, children);
};

const addSpecifiedClasses = (element, classesToAdd) =>
	React.cloneElement(element, {
		...element.props,
		className: `${classesToAdd} ${element.props.className || ""}`,
	});

const addIdFromText = element => {
	let id = getStringsFromChildren(element);
	id = removeNonAlphanumericCharacters(id);
	id = id.toLowerCase();
	return React.cloneElement(element, { ...element.props, id });
};

const getStringsFromChildren = element => {
	if (typeof element === "string") {
		return element;
	}

	let children = element.props.children;

	if (!children.filter) {
		children = [children];
	}

	return children.reduce(
		(acc, next) => acc + " " + getStringsFromChildren(next),
		""
	);
};

const removeNonAlphanumericCharacters = s => s.replace(/[^0-9a-z]/gi, "");
